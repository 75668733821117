<div
  class="footer"
  [ngClass]="{
    persistentMenu: environment.persistentMenu,
    'indent-left': isSideMenuOpen
  }"
>
  <div
    *ngIf="showExitLink || showHomeLink"
    class="footer-left"
    [ngClass]="{ off: isModalOpen }"
  >
    <div
      *ngIf="showExitLink"
      class="back-button pill-btn alt arrow-left"
      (click)="exitClick()"
    >
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.872 22.047">
          <path d="M16.872.031V0L0 11.023l16.872 11.023v-.031" />
        </svg>
      </div>
      <span>Exit</span>
    </div>
    <div
      *ngIf="showHomeLink"
      class="home-button pill-btn alt arrow-left"
      (click)="homeClick()"
    >
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37">
          <polygon
            points="37 15.15 36.96 15.15 36.96 37 22.25 37 22.25 24.59 14.17 24.59 14.17 37 0.05 37 0.05 15.15 0 15.15 18.5 0 37 15.15"
          />
        </svg>
      </div>
      <span>Home</span>
    </div>
  </div>

  <div class="footer-right" *ngIf="environment.showName === 'ddw'">
    <p class="legal">All rights reserved. September 2020 VV MEDMAT-22589</p>
  </div>

  <div
    *ngIf="environment.footerType === 'complex'"
    class="footer-bar footer-complex"
    [ngClass]="{
      persistentMenu: environment.persistentMenu,
      'size-wider': environment.menuType === 'images'
    }"
  >
    <div class="footer-copy">
      <div class="fcopy-row1">
        <p class="larger">
          THE INFORMATION ON THIS SITE IS FOR HEALTHCARE PROFESSIONALS
        </p>
      </div>
      <div class="fcopy-row2">
        <p>©2021 Takeda Pharmaceuticals U.S.A., Inc.</p>
        <p>
          TAKEDA and the TAKEDA logo are trademarks or registered trademarks of
          Takeda Pharmaceutical Company Limited
        </p>
        <p>US-XMP-1313v3.0 09/21</p>
      </div>
    </div>
    <div class="footer-buttons">
      <a href="#" (click)="$event.preventDefault(); medicalRequest()"
        >Medical Information Request</a
      >
    </div>
  </div>

  <div
    *ngIf="environment.footerType === 'default'"
    class="footer-bar"
    [ngClass]="{
      persistentMenu: environment.persistentMenu,
      'size-wider': environment.menuType === 'images'
    }"
  >
    <p class="footer-intent">
      The information on this website is intended for Healthcare Professionals
      Only. Efficacy and Safety of these products, for the uses identified
      within, are currently under investigation. Regulatory approval of these
      products or uses is dependent on the completion of the study programs and
      review by regulatory authorities.
    </p>
    <div class="footer-buttons">
      <!-- <a href="#" (click)="setModalById('pdf_sample_modal')"
        >PRODUCT INFORMATION</a
      > -->
      <a href="javascript:void(0);" (click)="medicalRequest()"
        ><img class="mirf-icon" src="../assets/images/mirf-icon.png" /><span>
          MEDICAL INFORMATION REQUEST FORM</span
        ></a
      >
    </div>
  </div>
</div>
