import { Component, Input, OnInit } from '@angular/core';
import {
  ModalService,
  HeaderService,
  PhotosphereService,
  SideMenuService,
  MedicalRequestFormWrapperService,
} from '../../services';
import { environment } from 'src/environments/environment';
import { Photosphere } from 'src/app/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() hideChat: boolean = false;

  public headState: any;
  public legalOpened: boolean = false;
  public showISI: boolean = false;
  public isModalOpen: boolean = false;
  public small: boolean = false;
  public showHomeLink: boolean = false;
  public environment: any = environment;
  public showExitLink: boolean = false;
  public isSideMenuOpen: boolean = false;
  public currentPhotosphere: string;
  public currentPhotosphereData: Photosphere;

  constructor(
    private modalSvc: ModalService,
    private headerSvc: HeaderService,
    private sideMenuSvc: SideMenuService,
    private photosphereSvc: PhotosphereService,
    private medicalRequestFormWrapperSvc: MedicalRequestFormWrapperService
  ) {}

  ngOnInit(): void {
    this.subscribe();
  }

  subscribe(): void {
    this.modalSvc.modalVisible.subscribe((data) => {
      this.isModalOpen = data;

      if (!data) {
        this.showISI = false;
        this.legalOpened = false;
      }
    });

    this.modalSvc.modalData.subscribe((data) => {
      if (data && this.isModalOpen) {
        this.showISI =
          data.modal_type === 'pdf' ||
          data.modal_type === 'video-player' ||
          data.modal_type === 'image';
      }
    });

    // this.headerSvc.headerState.subscribe(data => {
    //   this.headState = data;

    //   if (this.headState === 'research-state') {
    //     this.small = false;
    //   } else {
    //     this.small = true;
    //   }
    // });

    this.photosphereSvc.currentPhotosphere.subscribe((data) => {
      this.currentPhotosphereData = data;
      this.currentPhotosphere = data.id;
      this.showHomeLink = environment.showHomeLink && data.id === 'center';
      this.showExitLink =
        environment.showExitLink && this.currentPhotosphereData.showExitButton;
    });

    this.sideMenuSvc.sideMenuState.subscribe((state) => {
      this.isSideMenuOpen = state == 'open-state';
    });
  }

  public exitClick(): void {
    if (environment.exitLinkType === 'modal') {
      this.modalSvc.setActiveModalById(environment.exitLinkValue);
    } else if (environment.exitLinkType === 'photosphere') {
      this.photosphereSvc.setCurrentPhotosphere(
        this.currentPhotosphereData.exitButtonTargetPhotosphere ||
          environment.exitLinkValue,
        undefined,
        true
      );
    }
    // this.modalSvc.setActiveModalById('exit');
    // switch (this.currentPhotosphere) {
    //   case 'home-info':
    //     this.photosphereSvc.setCurrentPhotosphere('home', undefined, true);
    //     break;
    //   case 'center':
    //     this.photosphereSvc.setCurrentPhotosphere('home', undefined, true);
    //     break;
    //   default:
    //     this.photosphereSvc.setCurrentPhotosphere('center', undefined, true);
    //     break;
    // }
  }

  public homeClick(): void {
    this.photosphereSvc.setCurrentPhotosphere(
      environment.homeLinkPhotosphereId,
      undefined,
      true
    );
  }

  public medicalRequest(): void {
    this.medicalRequestFormWrapperSvc.open();
  }

  /*
  public openLegal(): void {
    this.legalOpened = !this.legalOpened;

    if (this.legalOpened) {
      this.googleSvc.sendGAEventById(4);
    }
  }
  */

  public externalLink(e, id): void {
    e.preventDefault();
    // const exLink = this.externalLinks.find(link => link.id === id);
    // this.googleSvc.sendGAEventById(exLink.tracking);
    // if (exLink) {
    //   window.open(exLink.url, '_blank');
    // }
  }

  public setModalById(modalId: string): void {
    this.modalSvc.setActiveModalById(modalId);
  }
}
