<div class="header" [ngClass]="{ on: showHeader && state == 'portal2-state' }">
  <div class="logo-home">
    <img class="header-logo" src="../assets/images/takeda_logo.png" />
    <a href="javascript:void(0);" (click)="home()">
      <img class="home-button" src="../assets/images/home-icon.png" />
    </a>
  </div>
  
  <div class="research-state header-state on">
    <div *ngIf="currentPhotosphere.portalTitle" class="header-item large on">
      <span [innerHTML]="currentPhotosphere.portalTitle"></span>
    </div>
    <ng-container *ngFor="let hotspot of currentPhotosphere.hotspots">
      <div
        *ngIf="hotspot.menuItem"
        class="header-item large"
        [class.on]="
          modalBelongToMenuItem(
            currentModal?.id,
            getHotspotData(hotspot.dataId)?.modal
          ) ||
          modalChildOf(currentModal?.id, getHotspotData(hotspot.dataId)?.modal)
        "
        (click)="setModalById(hotspot.dataId, hotspot)"
      >
        <span [innerHTML]="hotspot.menuItem"></span>
      </div>
    </ng-container>
  </div>
</div>
