export const commonEnv = {
  assetBasePath: "assets/",
  showName: "ddw",
  fov: 52,
  persistentMenu: true,
  menuButtonType: "default",
  menuTitle: "Product Portfolio",
  menuType: "images",
  menuTooltips: true,
  footerType: "complex",
  disablePhotosphereRotate: true,
  showHomeLink: false,
  homeLinkPhotosphereId: "home",
  showExitLink: false,
  exitLinkType: "modal",
  exitLinkValue: "exit",
  showMenuButtonWhenHeaderVisible: false,
  autoOpenMenuOnHome: false,
  welcomeSettings: {
    countryGate: false,
    rememberHcpGate: false,
    title: "Welcome to the<br />Takeda Virtual Experience",
    veevaCode: "",
    disclaimer: "This booth is intended for Healthcare Professionals Only.",
    footerCopy: "",
    showFooter: false,
  },
  contentUrlRules: [],
};
