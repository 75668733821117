import { Component, OnInit, ElementRef } from "@angular/core";
import {
  PhotosphereService,
  ModalService,
  HeaderService,
  SideMenuService,
  SideMenuState,
  MedicalRequestFormWrapperService,
  GoogleService,
} from "../../services";
import Photospheres from "../../../assets/data/base/photospheres.base.json";
import Hotspots from "../../../assets/data/base/hotspotData.base.json";
import Modals from "../../../assets/data/base/modals.base.json";
import Videos from "../../../assets/data/base/videos.base.json";
import SideMenuItems from "../../../assets/data/base/sidemenuData.base.json";
import SideMenuImages from "../../../assets/data/base/sidemenuImages.base.json";
import SideMenuTooltips from "../../../assets/data/base/sidemenuTooltips.base.json";
import SideMenuSpeical from "../../../assets/data/base/sidemenuSpecial.base.json";
import * as GTM from "../../utils/GTM";
import {
  Modal,
  Photosphere,
  SideMenuItem,
  SideMenuItemTargetType,
  SideMenuItemType,
} from "src/app/models";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  public giDirection: boolean = false;
  public researchDirection: boolean = false;
  public state: any = SideMenuState.CLOSED;
  public modal: string;
  public hideIcon: boolean = false;
  public showShadow: boolean = false;
  public currentPhotosphere: Photosphere | string = undefined;
  public currentPhotosphereData: Photosphere = null;
  public twilioChatRef: any;
  public environment: any = environment;
  // Items for "default" menuType
  public SideMenuItemTypes = SideMenuItemType;
  public menuItems = SideMenuItems as SideMenuItem[];
  // Items for "images" menuType
  public menuItemsImages = SideMenuImages;
  // Tooltips for "images" menuType
  public toolTips: any = SideMenuTooltips;
  public currentTooltipIndex: number = -1;
  // Items for special area in "images" menuType
  public menuItemsSpecial = SideMenuSpeical;

  constructor(
    private photosphereSvc: PhotosphereService,
    private modalSvc: ModalService,
    private headerSvc: HeaderService,
    private sideMenuSvc: SideMenuService,
    private medicalRequestFormWrapperSvc: MedicalRequestFormWrapperService,
    private router: Router,
    private googleSvc: GoogleService
  ) {}

  ngOnInit(): void {
    this.setState(SideMenuState.CLOSED);
    this.subscribe();
    this.twilioChatRef = window["twilioChat"];
  }

  subscribe(): void {
    this.modalSvc.modalData.subscribe((data) => {
      this.modal = data ? data.id : data;

      if (data) {
        if (window.location.pathname !== "/") {
          this.setState(SideMenuState.CLOSED);
        }
      }
    });

    this.photosphereSvc.currentPhotosphere.subscribe((data) => {
      this.currentPhotosphereData = data;
      this.currentPhotosphere = data ? data.id : data;
      // if (window.location.pathname !== '/') {
      if (this.currentPhotosphere === "home") {
        if (environment.autoOpenMenuOnHome) this.setState(SideMenuState.OPEN);
      } else {
        this.setState(SideMenuState.CLOSED);
      }
    });

    this.headerSvc.headerState.subscribe((data) => {
      this.showShadow =
        environment.showMenuButtonWhenHeaderVisible && data === "portal2-state";
      this.hideIcon =
        !environment.showMenuButtonWhenHeaderVisible &&
        data === "portal2-state";
    });

    this.sideMenuSvc.sideMenuState.subscribe((data) => {
      if (data === this.state) {
        return;
      }
      this.state = data;
    });
  }

  setState(state): void {
    //console.error('state STATE', state);
    this.state = state;
    this.sideMenuSvc.setSideMenuState(state);
  }

  public setPhotosphere(photosphereId: string): void {
    this.modalSvc.hideModal();
    //const hotspot = Hotspots.find((hs) => hs.id === hotspotId);
    this.photosphereSvc.setCurrentPhotosphere(photosphereId, undefined, true);

    this.setState(SideMenuState.CLOSED);
  }

  public setHotspot(hotspotId: string): void {
    this.modalSvc.hideModal();
    const hotspot = Hotspots.find((hs) => hs.id === hotspotId);
    this.photosphereSvc.setCurrentPhotosphere(
      hotspot.photosphere,
      hotspot.photosphereRotation,
      true
    );

    this.setState(SideMenuState.CLOSED);
  }

  public openHeader(e, bOpen: boolean): void {
    e.preventDefault();

    if (bOpen) {
      this.setState(SideMenuState.OPEN);
    } else {
      if (this.currentPhotosphere !== "home")
        this.setState(SideMenuState.CLOSED);
    }
  }

  public setModalById(modalId: string): void {
    this.modalSvc.setActiveModalById(modalId);
  }

  public tooltipShow(event: any, index: number): void {
    if (index > -1) {
      let target = event.target || event.srcElement || event.currentTarget;
      if (this.currentTooltipIndex > -1) {
        this.toolTips[this.currentTooltipIndex].show = false;
      }
      this.currentTooltipIndex = index;
      let tt_elem = target.querySelectorAll(".side-tooltip")[0];
      let coords = tt_elem.getBoundingClientRect();
      this.toolTips[index].show = true;
      this.toolTips[index].x = coords.x + "px";
      this.toolTips[index].y = coords.y + "px";
    }
  }

  public tooltipHide(event: any, index: number): void {
    if (index > -1) {
      let target = event.relatedTarget || event.toTarget;
      let show =
        this.currentTooltipIndex === index &&
        target &&
        (target.classList.contains("side-tooltip") ||
          target.classList.contains("side-box"));
      this.toolTips[index].show = show;
    }
  }

  public medicalRequest(): void {
    this.medicalRequestFormWrapperSvc.open();
  }

  public onMenuItemClick(menuItem: any) {
    if (menuItem.gtmTitle && menuItem.gtmUrl) {
      this.googleSvc.sendGAEventByData({
        event: "navigation",
        eventCategory: menuItem.gtmTitle,
        eventAction: location.origin + "/" + menuItem.gtmUrl,
        eventLabel: "",
      });
    }
    if (menuItem.targetType == SideMenuItemTargetType.Hotspot) {
      let return_value = this.setHotspot(menuItem.targetId);
      if (menuItem.modalId) {
        let me = this;
        setTimeout(function () {
          me.setModalById(menuItem.modalId);
        }, 1500);
      }
      return return_value;
    }

    if (menuItem.targetType == SideMenuItemTargetType.Photosphere) {
      return this.setPhotosphere(menuItem.targetId);
    }

    if (menuItem.targetType == SideMenuItemTargetType.Modal) {
      return this.setModalById(menuItem.targetId);
    }

    if (menuItem.targetType == SideMenuItemTargetType.MedForm) {
      return this.medicalRequest();
    }

    if (
      menuItem.targetType == SideMenuItemTargetType.ExternalLink &&
      menuItem.targetId
    ) {
      return this.externalLink(null, menuItem.targetId, menuItem.text);
    }
  }

  public externalLink(e: any, link: any, gtmTitle: any = null): void {
    if (e) {
      e.preventDefault();
      if (link === "" && e.target && e.target.href) {
        link = e.target.href;
      }
    }
    if (link !== "") {
      this.googleSvc.sendExternalLinkEvent(gtmTitle, link);
      window.open(link, "_blank");
    }
  }
}
