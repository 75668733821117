
import GTMEvents from '../../assets/data/gtmEvents.json';
import { environment } from 'src/environments/environment';

/*
* Send Google Tag Manager Events
*/
const dataLayer = window['dataLayer'];
console.log(dataLayer, "is the dataLayer #1");
const prod = (!(window.location.host.indexOf('localhost') > -1) && !(window.location.host.indexOf('stage') > -1));
//const prod = true;

export function sendGTMEvent(id: any, gtmObject: boolean = false): void {
    let event = undefined;
    if (gtmObject) {
        event = id;
    } else {
        event = GTMEvents.find(ev => ev.id === id);
    }
    if (event && prod) {
        // strip out html from category/label
        let tmp;
        if (event.eventLabel) {
            tmp = document.createElement('div');
            tmp.innerHTML = event.eventLabel;
            event.eventLabel = tmp.textContent || tmp.innerText || '';
        }
        if (event.eventCategory) {
            tmp = document.createElement('div');
            tmp.innerHTML = event.eventCategory;
            event.eventCategory = tmp.textContent || tmp.innerText || '';
        }
        dataLayer.push(event);
    }
    if (event) {
        console.log('gtm', id, event);
    }
}

export function sendHCPEvent(bHCP: boolean): void {
    const event = {
        event: 'hcp',
        event_category: bHCP ? 'yes' : 'no'
    };
    console.log('gtm', event);
    if (prod) {
        dataLayer.push(event);
    }
}

export function sendCountryEvent(sCountry: string): void {
    const event = {
        event: 'country_select',
        event_category: sCountry
    };
    console.log('gtm', event);
    if (prod) {
        dataLayer.push(event);
    }
}
