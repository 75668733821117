<div class="shadowbox">
  <div class="hi-modal" [ngClass]="{ redirect: showRedirect }">
    <div class="welcome-content" [ngClass]="{ on: !showRedirect }">
      <div class="inner">
        <div class="logo-and-title">
          <img
            class="welcome-logo"
            src="../../../assets/images/takeda_logo.png"
          />
          <h2 [innerHTML]="environment.welcomeSettings.title"></h2>
        </div>

        <div *ngIf="environment.showName != 'coe'">
          <div class="healthcare-prompt" [ngClass]="{ on: showHealthcare }">
            <p>Are You A Healthcare<br />Professional?</p>
            <a
              href="#"
              class="hi-modal-button"
              (click)="setCountryPrompt($event)"
              ><span>YES</span></a
            >
            <a href="#" class="hi-modal-button" (click)="setRedirect($event)"
              ><span>NO</span></a
            >
          </div>

          <div class="country-prompt" [ngClass]="{ on: showCountry }">
            <div class="input-container">
              <label for="countries">Select Country<br />of Residence</label>

              <div class="select-container">
                <select
                  name="countries"
                  class="country-drop-down"
                  (change)="setContinue($event)"
                >
                  <option value="none" selected disabled hidden>Country</option>
                  <option
                    *ngFor="let country of validCountries"
                    [value]="country"
                  >
                    {{ country }}
                  </option>
                </select>
              </div>
            </div>

            <br />

            <a
              href="#"
              class="hi-modal-button continue-button"
              [ngClass]="{ on: showContinue }"
              (click)="closeWelcome($event)"
              ><span>CONTINUE</span></a
            >
          </div>
        </div>

        <div
          *ngIf="environment.showName == 'coe'"
          class="email-and-function-prompt"
        >
          <form
            #hcpGateForm="ngForm"
            novalidate
            (ngSubmit)="submitForm(hcpGateForm)"
          >
            <div class="input-container">
              <label for="countries">Select Function</label>

              <div class="select-container">
                <select
                  name="countries"
                  class="country-drop-down"
                  required
                  [(ngModel)]="selectedFunction"
                >
                  <option value="" selected disabled hidden>Function</option>
                  <option
                    *ngFor="let function of validFunctions"
                    [value]="function"
                  >
                    {{ function }}
                  </option>
                </select>
              </div>
            </div>

            <div class="input-container">
              <label for="countries">Email Address</label>

              <div class="input">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                  email
                  [(ngModel)]="email"
                />
              </div>
            </div>

            <br />

            <button
              type="submit"
              class="hi-modal-button continue-button on"
              [disabled]="hcpGateForm.invalid"
            >
              <span>CONTINUE</span>
            </button>
          </form>
        </div>

        <p
          class="healthcare-intent"
          *ngIf="showHealthcare && environment.welcomeSettings.disclaimer"
          [innerHTML]="environment.welcomeSettings.disclaimer"
        ></p>
      </div>

      <div *ngIf="environment.welcomeSettings.showFooter" class="modal-footer">
        <div
          class="copyright"
          [innerHTML]="environment.welcomeSettings.footerCopy"
        ></div>
        <div *ngIf="environment.welcomeSettings.veevaCode" class="veeva-code">
          {{ environment.welcomeSettings.veevaCode }}
        </div>
      </div>
    </div>

    <div
      class="welcome-redirect"
      [ngClass]="{ on: showRedirect && !showRedirecting }"
    >
      <h2>IMPORTANT NOTICE</h2>
      <p class="redirect-text">
        This site is intended for Healthcare Professionals Only. <br>
        As a non-Healthcare Professional, you will be <br>
        redirected to <a [href]="redirectUrl">www.takeda.com</a>
      </p>
    </div>
    <div class="welcome-redirecting" [ngClass]="{ on: showRedirecting }">
      <p class="redirect-text">
        You are being redirected to <a [href]="redirectUrl">www.takeda.com</a>
      </p>
    </div>
  </div>
</div>

<div class="cover"></div>
